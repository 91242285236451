<template>
  <div class="scrollRowBar-edit">11</div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit';
import editModuleMixin from '../../mixins/editModuleMixin';
export default {
  name: 'scroll-row-bar-edit',
  mixins: [langEditMixin, editModuleMixin],
};
</script>

<style lang="less" scoped></style>
